<template>
  <transition
    enter-class="opacity-0"
    enter-active-class="ease-out transition-medium"
    enter-to-class="opacity-100"
    leave-class="opacity-100"
    leave-active-class="ease-out transition-medium"
    leave-to-class="opacity-0"
  >
    <div
      @keydown.esc="isOpen = false"
      v-show="showBackdrop"
      class="fixed inset-0 transition-opacity"
    >
      <div
        @click="isOpen = false"
        class="absolute inset-0 bg-black opacity-50"
        tabindex="0"
      ></div>
    </div>
  </transition>

  <div
    class="
      w-100
      mx-auto
      bg-white
      border-t-4
      rounded
      border-indigo-500
      dark:bg-gray-800
      shadow-md
      font-sans
      text-center
      p-4
    "
  >
    <div class="overflow-hidden">
      <div class="flex flex-wrap item-center justify-between m-2 mb-6">
        <h1 class="text-2xl text-left font-medium text-gray-800">
          Configurações do sistema
        </h1>
      </div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div>
        <div class="flex flex-wrap item-center justify-between m-2 mb-6">
          <h1 class="text-xl text-left font-medium text-gray-800">Séries</h1>
          <button
            @click="showNewSeriesModalClick"
            class="
              px-3
              py-2
              bg-gray-800
              text-white text-xs
              font-bold
              uppercase
              rounded
              bg-indigo-600
              hover:bg-indigo-700
              focus:ring-indigo-500 focus:ring-offset-indigo-200
              transition
              ease-in
              duration-200
              font-semibold
              shadow-md
              focus:outline-none focus:ring-2 focus:ring-offset-2
            "
          >
            Nova série
          </button>
        </div>
        <ConfigYearTable
          :config="config"
          :loading="loadingConfig"
          :updateSeries="updateSeries"
          :onEditSeries="onEditSeries"
        />
      </div>

      <div>
        <div class="flex flex-wrap item-center justify-between m-2 mb-6">
          <h1 class="text-xl text-left font-medium text-gray-800">Ciclos</h1>
          <button
            @click="showNewCycleModalClick"
            class="
              px-3
              py-2
              bg-gray-800
              text-white text-xs
              font-bold
              uppercase
              rounded
              bg-indigo-600
              hover:bg-indigo-700
              focus:ring-indigo-500 focus:ring-offset-indigo-200
              transition
              ease-in
              duration-200
              font-semibold
              shadow-md
              focus:outline-none focus:ring-2 focus:ring-offset-2
            "
          >
            Novo ciclo
          </button>
        </div>
        <ConfigCycleTable
          :config="config"
          :loading="loadingConfig"
          :updateCycle="updateCycle"
          :onEditCycle="onEditCycle"
        />
      </div>
    </div>
  </div>

  <div
    v-if="showNewSeriesModal"
    class="
      modal
      shadow-lg
      rounded-2xl
      p-4
      bg-white
      h-3/5
      w-full
      md:w-4/5
      lg:w-2/5
      m-auto
      z-30
      transform
      top-0
      left-0
      bg-white
    "
  >
    <div class="w-full h-full text-center">
      <div class="flex h-full flex-col justify-between">
        <div
          class="
            self-center
            mt-2
            mb-2
            text-xl
            font-light
            text-gray-800
            sm:text-2xl
            dark:text-white
          "
        >
          {{ operation === "add" ? "Nova série" : "Editar série" }}
        </div>
        <div class="p-2 mt-2">
          <form>
            <div class="flex flex-col mb-2">
              <div class="relative text-left">
                <input
                  type="text"
                  required
                  :disabled="creatingNewSeries"
                  id="create-series"
                  class="
                    rounded-lg
                    border-transparent
                    flex-1
                    appearance-none
                    border border-gray-300
                    w-full
                    py-2
                    px-4
                    bg-white
                    text-gray-700
                    placeholder-gray-400
                    shadow-sm
                    text-base
                    focus:outline-none
                    focus:ring-2
                    focus:ring-purple-600
                    focus:border-transparent
                  "
                  name="series"
                  v-model="series"
                  placeholder="Série"
                  @blur="validateInput('series')"
                />
                <small
                  v-if="errors.series"
                  id="seriesError"
                  class="text-red-500"
                >
                  {{ errors.series }}
                </small>
              </div>
            </div>
          </form>
        </div>

        <div class="flex items-center justify-between gap-4 w-full mt-4">
          <button
            type="button"
            :disabled="this.errors.series || creatingNewSeries"
            @click="onNewSeries"
            class="
              py-2
              px-4
              bg-indigo-600
              hover:bg-indigo-700
              focus:ring-indigo-500 focus:ring-offset-indigo-200
              text-white
              w-full
              transition
              ease-in
              duration-200
              text-center text-base
              font-semibold
              shadow-md
              focus:outline-none focus:ring-2 focus:ring-offset-2
              rounded-lg
              disabled:opacity-10
            "
          >
            <svg
              v-if="creatingNewSeries"
              class="
                relative
                left-1/2
                animate-spin
                -ml-1
                mr-3
                h-5
                w-5
                text-white
              "
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            <span v-if="!creatingNewSeries">{{
              operation === "add" ? "Criar" : "Atualizar"
            }}</span>
          </button>
          <button
            type="button"
            @click="onCloseNewSeriesModal"
            class="
              py-2
              px-4
              bg-white
              hover:bg-gray-100
              focus:ring-indigo-500 focus:ring-offset-indigo-200
              text-indigo-500 text-white
              w-full
              transition
              ease-in
              duration-200
              text-center text-base
              font-semibold
              shadow-md
              focus:outline-none focus:ring-2 focus:ring-offset-2
              rounded-lg
            "
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="showNewCycleModal"
    class="
      modal
      shadow-lg
      rounded-2xl
      p-4
      bg-white
      h-3/5
      w-full
      md:w-4/5
      lg:w-2/5
      m-auto
      z-30
      transform
      top-0
      left-0
      bg-white
    "
  >
    <div class="w-full h-full text-center">
      <div class="flex h-full flex-col justify-between">
        <div
          class="
            self-center
            mt-2
            mb-2
            text-xl
            font-light
            text-gray-800
            sm:text-2xl
            dark:text-white
          "
        >
          {{ operation === "add" ? "Novo ciclo" : "Editar ciclo" }}
        </div>
        <div class="p-2 mt-2">
          <form>
            <div class="flex flex-col mb-2">
              <div class="relative text-left">
                <input
                  type="text"
                  required
                  :disabled="creatingNewCycle"
                  id="create-cycle"
                  class="
                    rounded-lg
                    border-transparent
                    flex-1
                    appearance-none
                    border border-gray-300
                    w-full
                    py-2
                    px-4
                    bg-white
                    text-gray-700
                    placeholder-gray-400
                    shadow-sm
                    text-base
                    focus:outline-none
                    focus:ring-2
                    focus:ring-purple-600
                    focus:border-transparent
                  "
                  name="cycle"
                  v-model="cycle"
                  placeholder="Ciclo"
                  @blur="validateInput('cycle')"
                />
                <small v-if="errors.cycle" id="cycleError" class="text-red-500">
                  {{ errors.cycle }}
                </small>
              </div>
            </div>
          </form>
        </div>

        <div class="flex items-center justify-between gap-4 w-full mt-4">
          <button
            type="button"
            :disabled="this.errors.cycle || creatingNewCycle"
            @click="onNewCycle"
            class="
              py-2
              px-4
              bg-indigo-600
              hover:bg-indigo-700
              focus:ring-indigo-500 focus:ring-offset-indigo-200
              text-white
              w-full
              transition
              ease-in
              duration-200
              text-center text-base
              font-semibold
              shadow-md
              focus:outline-none focus:ring-2 focus:ring-offset-2
              rounded-lg
              disabled:opacity-10
            "
          >
            <svg
              v-if="creatingNewCycle"
              class="
                relative
                left-1/2
                animate-spin
                -ml-1
                mr-3
                h-5
                w-5
                text-white
              "
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            <span v-if="!creatingNewCycle">{{
              operation === "add" ? "Criar" : "Atualizar"
            }}</span>
          </button>
          <button
            type="button"
            @click="onCloseNewCycleModal"
            class="
              py-2
              px-4
              bg-white
              hover:bg-gray-100
              focus:ring-indigo-500 focus:ring-offset-indigo-200
              text-indigo-500 text-white
              w-full
              transition
              ease-in
              duration-200
              text-center text-base
              font-semibold
              shadow-md
              focus:outline-none focus:ring-2 focus:ring-offset-2
              rounded-lg
            "
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from "vue";
import Firebase from "@/firebase";
import ConfigCycleTable from "@/components/ConfigCycleTable";
import ConfigYearTable from "@/components/ConfigYearTable";
import { v4 as uuidv4 } from "uuid";

export default {
  components: { ConfigCycleTable, ConfigYearTable },
  setup() {
    const loadingConfig = ref(false);
    const config = ref(null);

    const getConfig = async () => {
      loadingConfig.value = true;
      const configSnapshot = await Firebase.getDoc("config/prod");
      config.value = configSnapshot.data();
      loadingConfig.value = false;
    };

    const updateSeries = async (operation, payload) => {
      loadingConfig.value = true;

      if (operation === "remove") {
        const { id } = payload;
        config.value = {
          ...config.value,
          series: config.value.series.filter((year) => year.id !== id),
        };
      }

      if (operation === "add") {
        const { id, nome } = payload;
        config.value = {
          ...config.value,
          series: [
            ...config.value.series,
            {
              id,
              nome,
            },
          ],
        };
      }

      if (operation === "update") {
        const { id, nome } = payload;
        config.value = {
          ...config.value,
          series: config.value.series.map((year) =>
            year.id === id ? { id, nome } : year
          ),
        };
      }

      await Firebase.setDoc("config/prod", config.value);

      loadingConfig.value = false;
    };

    const updateCycle = async (operation, payload) => {
      loadingConfig.value = true;

      if (operation === "remove") {
        const { id } = payload;
        config.value = {
          ...config.value,
          ciclos: config.value.ciclos.filter((cycle) => cycle.id !== id),
        };
      }

      if (operation === "add") {
        const { id, nome } = payload;
        config.value = {
          ...config.value,
          ciclos: [
            ...config.value.ciclos,
            {
              id,
              nome,
            },
          ],
        };
      }

      if (operation === "update") {
        const { id, nome } = payload;
        config.value = {
          ...config.value,
          ciclos: config.value.ciclos.map((year) =>
            year.id === id ? { id, nome } : year
          ),
        };
      }

      await Firebase.setDoc("config/prod", config.value);

      loadingConfig.value = false;
    };

    const errors = reactive({
      series: null,
      cycle: null,
    });

    return {
      errors,
      loadingConfig,
      config,
      getConfig,
      updateSeries,
      updateCycle,
    };
  },
  mounted() {
    this.getConfig();
  },

  data() {
    return {
      series: "",
      cycle: "",
      seriesIdBeingEdited: null,
      cycleIdBeingEdited: null,
      showBackdrop: false,
      showNewSeriesModal: false,
      showNewCycleModal: false,
      creatingNewSeries: false,
      creatingNewCycle: false,
    };
  },
  methods: {
    validateInput(formName) {
      if (formName === "series") {
        if (!this.series || this.series.length === 0) {
          this.errors.series = "Nome para série deve ter no mínimo 1 caractére";
        } else {
          this.errors.series = false;
        }
      }
      if (formName === "cycle") {
        if (!this.cycle || this.cycle.length === 0) {
          this.errors.cycle = "Nome para série deve ter no mínimo 1 caractére";
        } else {
          this.errors.cycle = false;
        }
      }
    },

    async onEditSeries(series) {
      this.seriesIdBeingEdited = series.id;
      this.series = series.nome;

      this.operation = "update";
      this.onShowNewSeriesModal();
    },

    async onEditCycle(cycle) {
      this.cycleIdBeingEdited = cycle.id;
      this.cycle = cycle.nome;

      this.operation = "update";
      this.onShowNewCycleModal();
    },

    async onNewSeries() {
      this.validateInput("series");

      if (this.errors.series || !this.series) {
        return;
      }

      if (this.operation === "add") {
        await this.updateSeries("add", {
          id: uuidv4(),
          nome: this.series,
        });
      }

      if (this.operation === "update") {
        await this.updateSeries("update", {
          id: this.seriesIdBeingEdited,
          nome: this.series,
        });
      }

      this.onCloseNewSeriesModal();
    },
    async onNewCycle() {
      this.validateInput("cycle");
      if (this.errors.cycle || !this.cycle) {
        return;
      }

      if (this.operation === "add") {
        await this.updateCycle("add", {
          id: uuidv4(),
          nome: this.cycle,
        });
      }

      if (this.operation === "update") {
        await this.updateCycle("update", {
          id: this.cycleIdBeingEdited,
          nome: this.cycle,
        });
      }

      this.onCloseNewCycleModal();
    },
    showNewSeriesModalClick() {
      this.operation = "add";
      this.onShowNewSeriesModal();
    },
    onShowNewSeriesModal() {
      this.showNewSeriesModal = true;
      this.showBackdrop = true;
    },
    onCloseNewSeriesModal() {
      this.showNewSeriesModal = false;
      this.showBackdrop = false;
      this.series = "";
    },
    showNewCycleModalClick() {
      this.operation = "add";
      this.onShowNewCycleModal();
    },
    onShowNewCycleModal() {
      this.showNewCycleModal = true;
      this.showBackdrop = true;
    },
    onCloseNewCycleModal() {
      this.showNewCycleModal = false;
      this.showBackdrop = false;
      this.cycle = "";
    },
  },
};
</script>

<style scoped>
.modal {
  position: fixed !important;
  height: auto !important;
  top: 100px !important;
  left: 0 !important;
  right: 0 !important;
}
</style>
