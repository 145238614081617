<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  Ciclo
                </th>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-center text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                ></th>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-center text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                ></th>
              </tr>
            </thead>

            <tbody
              v-if="
                (!config?.ciclos || config?.ciclos?.length === 0) && !loading
              "
            >
              <tr>
                <td colspan="3" class="px-6 py-8 whitespace-nowrap">
                  Não existem ciclos cadastrados
                </td>
              </tr>
            </tbody>

            <tbody v-if="loading">
              <tr>
                <td colspan="3" class="px-6 py-8 whitespace-nowrap">
                  <svg
                    class="
                      relative
                      left-1/2
                      animate-spin
                      -ml-1
                      mr-3
                      h-5
                      w-5
                      text-purple-600
                    "
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </td>
              </tr>
            </tbody>

            <tbody
              v-if="config && !loading"
              class="bg-white divide-y divide-gray-200"
            >
              <tr v-for="(cycle, index) in config.ciclos" :key="index">
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-md font-medium text-gray-900">
                        {{ cycle.nome }}
                      </div>
                    </div>
                  </div>
                </td>
                <td
                  class="
                    px-4
                    py-4
                    whitespace-nowrap
                    text-right text-sm
                    font-medium
                  "
                >
                  <a
                    @click="onEditCycle(cycle)"
                    class="cursor-pointer text-indigo-600 hover:text-indigo-900"
                    >Gerenciar</a
                  >
                </td>
                <td
                  class="
                    px-4
                    py-4
                    whitespace-nowrap
                    text-right text-sm
                    font-medium
                  "
                >
                  <a
                    class="cursor-pointer text-indigo-600 hover:text-indigo-900"
                    @click="emitUpdateCycle('remove', cycle)"
                    >Remover</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  props: ["config", "loading", "updateCycle", "onEditCycle"],
  methods: {
    emitUpdateCycle(operation, payload) {
      Swal.fire({
        title: "Você tem certeza?",
        text: `Você realmente quer excluir o ciclo '${payload.nome}'?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Não, cancelar",
        confirmButtonText: "Sim, excluir",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.updateCycle(operation, payload);
        }
      });
    },
  },
};
</script>
